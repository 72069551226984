.contact-page {
  position: relative;
}
.profile-page .chip, .contact-form-content .chip, .collective_content .chip, .doctor-signup-form .chip.update-doctor-detail {
  gap: 5px;
  background: var(--foundation-violet);
  font-size: 14px;
  color: var(--foundation-btn-color);
  font-family: var(--assistant);
}

.contact-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-content {
  width: calc(100% - 500px);
}
.rsd__react-select-datepicker label {
  display: none !important;
}

.rsd__select-month:focus-visible,
.rsd__select-day:focus-visible,
.rsd__select-year:focus-visible {
  outline-color: #000;
}

.contact-form {
  max-width: 670px;
  width: 100%;
  background: #ffffff;
  /* cards shadow */

  /* box-shadow: 0px 24.5184px 32.0625px rgba(0, 0, 0, 0.1); */
  border-radius: 20px;
  /* padding: 50px 50px 23px; */
  position: relative;
  margin: 0px auto;
}
.form-date-picker .react-date-picker__wrapper {
  border: 2px solid #18457d;
  border-radius: 30px;
  height: 45px;
  box-shadow: none !important;
  outline: 0 !important;
  background: #fff;
  color: #18457d;
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.5;
  width: 100%;
}
.text {
  text-align: center;
  color: #14427d;
  font-family: almoni-light, sans-serif !important;
}

.contact-content h2 {
  padding-top: 20px;
  margin: 0;
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}

.contact-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
}

.contact-info-grid {
  width: 48%;
  display: flex;
  position: relative;
  padding-left: 30px;
}

.contact-info-grid img {
  position: absolute;
  top: 5px;
  left: 0;
}

.contact-info-text span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
  max-width: 280px;
}

.location-place img {
  width: 100%;
}

.contact-info-text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #333333;
  margin: 0;
  padding-top: 8px;
}

.contact-form-content h2 {
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
  color: #000;
  font-size: 49px;
  line-height: 38px;
  font-family: var(--assistant) !important;
}

.contact-form-content p {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin: 0;
}

.contact-form-content form label {
  color: #000;
  font-size: 18px;
  line-height: 27px;
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-family: var(--assistant) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.contact-form-content form .form-control {
  border: 2px solid #000;
  border-radius: 90px;
  font-size: 18px;
  text-indent: 0;
  color: #505050;
  line-height: 27px;
  width: 100%;
  margin-bottom: 10px;
  height: auto;
}
.searchWrapper {
  text-align: initial;
  padding: 0 !important;
}

.phone_number {
  direction: ltr !important;
}
.textcenter {
  text-align: center;
}
.selectData {
  text-align: initial !important;
}

.contact-form-content form .form-control::placeholder {
  color: #ced0d0;
}
.summaries-btn {
  width: 100%;
}
.summaries-btn button {
  font-weight: 500 !important;
  font-size: 20px !important;
  height: 44px !important;
  width: 40% !important;
  margin:  20px 0px !important;
}

.contact-form-content .btn {
  background: var(--foundation-violet);
  border-radius: 33px;
  border-color: var(--foundation-violet);
  border-width: 2px;
  width: 48%;
  height: 54px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: var(--foundation-btn-color);
  margin-top: 20px;
  box-shadow: none !important;
  transition: all 0.5s;
  position: relative;
  font-family: var(--assistant) !important;
}
.contact-form-content .switch-standard .btn {
  background: transparent;
  color: var(--foundation-violet);
  font-size: 14px;
  min-width: 24%;
  width: unset;
  height: 44px;
  margin: 10px 0px;
}

.contact-form-content .switch-standard .btn:hover {
  background: var(--foundation-violet) !important;
  color: var(--foundation-btn-color) !important;
}

.contact-form-content form textarea.form-control {
  height: 155px;
  border-radius: 10px;
}

.contact-form-content .btn:hover {
  background: transparent !important;
  border-color: var(--foundation-violet) !important;
  color: var(--text-black);
  border-width: 2px;
}

.contact-form-content .btn:hover:after {
  display: inline-block;
}

.contact-form-content .btn:focus, .contact-form-content .btn:active {
  background: var(--foundation-violet);
  border-color: var(--foundation-violet);
}
.contact-form-content .btn:disabled {
  background: var(--foundation-violet);
  border-color: var(--foundation-violet);
}

.success-msg {
  color: #5dd979;
  padding-top: 20px;
  text-align: center;
  font-weight: 500;
}

.error-msg {
  color: #f35c5c;
  padding-top: 20px;
  text-align: center;
  font-weight: 500;
}

.contact-form .firstname-form,
.lastname-form {
  width: 48%;
}

.contact-form .phone-form {
  width: 60%;
}

.contact-form .number-form {
  width: 35%;
}

.contact-form form .form-group {
  width: 100%;
}

.contact-form form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
}

.contact-form .send-btn,
.contact-form .google-recptcha {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.check {
  width: 100%;
  display: block;
}

.check input {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.profileDefination {
  display: block;
  justify-content: center;
  align-items: center;
}

.textStyle {
  margin: 0;
  padding: 0 30px;
  border-inline-end: 1px solid #ccc;
  border-radius: 0px;
  padding-top: 50px;
}

.saveclass {
  top: 200px;
  left: 67%;
  margin-top: 10%;
  text-align: center;
}

.personal button {
  padding: 5px 133px 5px 136px;
  border-radius: 35px;
  border: 4px solid #14427d;
  color: #14427d;
  background: none;
  font-size: 41px;
  font-weight: 300;
  margin-bottom: 27px;

  transition: all 0.3s;
}
.personal button:hover {
  color: white;
  background-color: #14427d;
}

.personal h4 {
  font-size: 36px;
  color: #14427d;
  font-weight: 500;
}

.personal h6 {
  font-size: 30px;
  font-weight: bolder;
  color: #14427d;
  padding-left: 29px;
}
.personal p {
  font-size: 22px;
  text-align: inherit;
  color: #14427d;
}
.password {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24%;
}
.password a {
  color: #14427d;
  font-size: 24px;
}
.password a:hover {
  color: #14427d;
}
.form {
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.textdiv h5 {
  text-align: center;
  color: #000;
  padding: 80px 24%;
  font-family: var(--assistant) !important;
}
.textdiv {
  border-top: 1px solid #ccc;
}
.update-team-photo {
  background: #14427d;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  height: 50px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin-top: 40px;
  position: relative;
}
.update-team-photo input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
}
.team-profile {
  width: 40%;
  background: #595959;
  /*    box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);*/
  border-radius: 20px;
  padding: 44px 20px 30px;
}
.team-profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}
.team-profile-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 3px solid #000;
  border-radius: 50%;
}

.imagecropmodal {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  background-color: #fff0;
  overflow: auto;
  width: 50%;
  margin: 30px auto;
  min-height: 50%;
}
.react-date-picker {
  border: 2px solid #14427d;
  border-radius: 30px;
  padding: 0;
}
.react-date-picker__inputGroup {
  font-weight: normal !important;
}
.modalDiv {
  display: flex;
  align-items: center;
}
.change-password .form-control {
  border-radius: 90px !important;
  position: relative;
  text-align: center;
}
.change-password .input-group span {
  width: unset;
  margin: 0;
  border-radius: 33px !important;
  height: 44px;
  position: absolute;
  right: 10px;
  z-index: 6;
  top: 10px;
  cursor: pointer;
}

.change-password .input-group span svg {
  color: var(--foundation-violet) !important;
}

:dir(rtl) {
   .change-password .input-group span {
    left: 10px;
    right: unset !important;
  }
 
}

/************************************************************************************************************/

/*************** Media Query ******************/

/************************************************************************************************************/

@media screen and (max-width: 767px) {

  .contact-content h2 {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .contact-content {
    width: 100%;
    padding-bottom: 45px;
  }

  .contact-form {
    z-index: 9;
    margin: 0px auto;
  }

  .contact-info-grid {
    width: 100%;
    padding-bottom: 20px;
  }

  .contact-form-content h2 {
    font-size: 30px;
  }

  .contact-form-content form label {
    font-size: 22px;
    text-align: right !important;
  }

  .contact-form-content form .form-control {
    font-size: 16px;
  }
  .imagecropmodal {
    margin: 0 auto;
    width: 75% !important;
  }
  .textdiv h5 {
    padding: 40px 0px;
  }
  .contact-form-content .btn {
    width: 70%;
  }
}

@media screen and (max-width: 375px) {
  .contact-form .firstname-form,
  .lastname-form {
    width: 100%;
  }
  .modalDiv {
    margin: 0 auto;
    width: 75% !important;
  }
}
