.create-prescription-page {
    padding: 80px 0px;
}

.create-prescription-title {
    padding-bottom: 40px;
}

.create-prescription-title h2 {
    text-align: center;
    font-family: var(--assistant) !important;
    color: #1E0F3C;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
}

.create-prescription-page .confirmation-agree-section {
    margin: 0;
}

.summary-section .toggleDiv {
    display: block;
}

.summary-section .modal-close-btn {
    display: none;
}

.prescription-alert .modal-content {
    border-radius: 8px;
    background: #FFF;
    padding: 48px 24px;
    border: 3px solid #000;
}

.prescription-alert .modal-body {
    padding: 0;
}

.prescription-alert h6 {
    color: #000;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 490px;
    margin: 0px auto;
    text-align: right;
    direction: rtl;
}

.contract-content.rescription-alert h6 {
    text-align: center;
}

.prescription-alert .modal-footer {
    border: none;
    justify-content: center;
    gap: 40px;
    padding: 0;
    padding-top: 48px;
}

.prescription-alert .modal-footer .border-btn {
    max-width: 165px;
}

/* **************** Add template CSS ************** */
.add-template-btn {
    display: flex;
    align-items: center;
    gap: 5px;
}

.add-template-btn .btn-primary {
    background-color: #c4c9cf;
    border-color: #c4c9cf;
    color: var(--text-primary);
    font-size: 16px;
    font-family: var(--assistant) !important;
    border-radius: 28px;
    font-weight: 600;
}

.add-template-modal .modal-content {
    padding-top: 0;
}

.add-template-modal .modal-header {
    border: none;
}

.add-template-modal .modal-header h6 {
    text-align: center;
}

.add-template-modal .modal-header h6 span {
    display: block;
    font-size: 18px;
    color: #685e7c;
    font-weight: 500;
}

.add-template-modal .btn-close {
    margin: 0;
}

.template-list-title p {
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
    margin-bottom: 16px;
}

.template-list-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.he-list .MuiChip-deleteIcon {
    margin: 0 -6px 0 5px !important;
}

.he-list .MuiChip-icon {
    margin-right: 4px !important;
    margin-left: 0px !important;
}

.template-form {
    padding-top: 30px;
}

.template-form form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.template-form .form-group {
    width: 100%;
}

.template-form .form-label {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    margin-bottom: 16px;
}

.template-form .form-group input,
.template-form .form-group input:focus,
.template-form textarea.form-control,
.template-form .form-select {
    border-radius: 4px;
    border: 2px solid #9891A5;
    background: #FFF;
    box-shadow: none !important;
    color: #000;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    outline: 0 !important;
    padding: 10px 16px;
    width: 100%;
    text-align: right;
}

.template-form .border-btn {
    max-width: 164px;
}

.template__multi-value {
    background: var(--foundation-violet) !important;
    font-size: 14px;
    color: var(--foundation-btn-color);
    font-family: var(--assistant);
    border-radius: 4px !important;
}

.template__multi-value__label {
    color: var(--foundation-btn-color) !important;
    font-family: var(--assistant);
    font-size: 14px !important;
}

.prescription-alert.delete-show .modal-content {
    background: #c4c9cf;
}

.prescription-alert.delete-template-modal .modal-content {
    padding-top: 0;
}

.delete-template-modal .btn-close {
    margin: 0;
}

.delete-template-modal .modal-header {
    border: none;
}

.yes-no-grid {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.yes-no-grid .border-btn {
    max-width: 164px;
}

.prescription-alert.delete-template-modal h6 {
    font-weight: 600;
    font-size: 32px;
}

.delete-show .template-form .form-group .form-control {
    background: transparent;
}

form.summarytext {
    position: relative;
}

.temp-list-modal .modal-content {
    width: 100%;
    border-radius: 8px;
    border: 2px solid #E9E7EC;
    background: #FFF;

    min-height: 40px;
    max-height: 249px;
    overflow-y: auto;
    z-index: 9;
}

.temp-list-modal .modal-body {
    padding: 0;

}

.temp-list-grid button {
    background: transparent;
    border: none;
    color: var(--text-black) !important;
    font-family: var(--assistant);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
    border: none;
    border-bottom: 1px solid #E9E7EC;
    padding: 8px 16px;
    border-radius: 0;
    text-align: unset;
    width: 100%;
}

.temp-list-grid button:focus-visible {
    background: #B9B5C3;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #E9E7EC;
    outline: 0;
}

.temp-list-grid button:hover {
    background: #B9B5C3 !important;
    border: none !important;
    border-bottom: 1px solid #E9E7EC;
}

.template-list-grid .MuiChip-filled.active-chip {
    background: var(--foundation-violet);
    color: var(--foundation-btn-color);
}

.template-list-grid .MuiChip-filled.active-chip:hover {
    background: var(--foundation-violet);
}

.template-list-grid .active-chip .MuiChip-deleteIcon {
    color: var(--foundation-btn-color) !important;
}

.no-temp-list {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
}

.no-temp-list h6 {
    font-size: 24px;
    font-weight: 500;
    padding: 10px 0;
    color: var(--foundation-violet);
    font-family: var(--assistant) !important;
    margin: 0;
}

@media (max-width: 767px) {
    .prescription-alert.delete-template-modal .modal-content {
        padding-left: 12px;
        padding-right: 12px;
    }

    .delete-template-modal .modal-header {
        padding-left: 0;
        padding-right: 0;
    }

    .prescription-alert.delete-template-modal h6 {
        font-size: 26px;
    }
}


.add-field-modal .modal-content {
    padding-top: 0;
}

.add-field-modal .modal-header {
    border: none;
}

.add-field-modal .modal-header .btn-close {
    margin: 0;
}

.add-field-modal .modal-header h6 {
    color: #1e0f3c;
    font-family: var(--assistant) !important;
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
}

.add-field-content .form-control {
    text-align: right;
}

.add-field-content .form-label {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    margin-bottom: 16px;
}

.add-field-content .form-group {
    margin-bottom: 24px;
}

.add-field-content .form-group input,
.add-field-content .form-group input:focus,
.form-date-picker .react-date-picker__wrapper,
.add-field-content .form-select {
    border-radius: 4px;
    border: 2px solid #9891A5;
    background: #FFF;
    box-shadow: none !important;
    color: #000;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    outline: 0 !important;
    padding: 10px 16px;
    width: 100%;
    text-align: right;
}

.add-field-content .form-select {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 150% !important;
    background: transparent;
}

.add-field-content .border-btn {
    max-width: 165px;
    /* margin: 0px auto !important; */
    /* margin-top: 48px !important; */
}

.field-btn-grid {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.field-btn-grid .btn-outline-danger {
    font-size: 18px !important;
    font-family: var(--assistant) !important;
    font-weight: bold;
    max-width: 165px;
    border-radius: 4px !important;
    border-width: 2px;
    padding: 16px !important;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.field-btn-grid .btn-outline-danger:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}

.hide-field {
    display: none;
}

.create-prescription-page .two_button-left {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
}

.delete-field .yes_no {
    gap: 30px;
    justify-content: center;
}

.prescription-alert.delete-show .modal-content {
    background: #c4c9cf;
}

.prescription-alert.delete-show .modal-content .form-control {
    background: transparent;
}


.rx-dob .MuiInputBase-root,
.rx-dob .MuiInputBase-root:hover {

    height: 48px;
}

.frequency .rx-dob .MuiInputBase-root,
.frequency .rx-dob .MuiInputBase-root:hover {
    height: 43px;
}

.rx-dob.MuiFormControl-root {
    width: 100%;
}

.rx-dob fieldset {
    border: none;
    border-radius: 4px;
    border: 2px solid #9891A5;
    border-color: #9891A5;

    box-shadow: none !important;
    color: #000;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    outline: 0 !important;
    padding: 0px 16px;
    width: 100%;
    text-align: right;
}

.frequency .rx-dob fieldset {
    border: 1px solid #ced4da;
    border-color: #ced4da;
}

.frequency.new-field .form-control  {
    height: 43px;
}
.frequency.new-field input[type="number"] {
    direction: rtl;
    text-align: center;
    font-family: var(--assistant) !important;
    color: #000;
}
.frequency.new-field .form-control:focus {
    color: #212529;
    background-color: #fff;
    border: 1px solid #86b7fe;
    outline: 0;
    box-shadow: none;
}

.rx-dob .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #9891A5;
}

.rx-dob .MuiInputBase-root.Mui-focused fieldset {
    border-width: 2px;
    border-image: linear-gradient(360deg, #FF0F7B 8.23%, #F89B29 94.91%);
    border-image-slice: 1;
    border-style: solid;
}

.rx-dob .MuiInputBase-root input,
.rx-dob .MuiInputBase-root input:focus {
    border: none;
    padding: 0;
}

.rx-dob fieldset:focus {
    border-width: 2px;
    border-image: linear-gradient(360deg, #FF0F7B 8.23%, #F89B29 94.91%);
    border-image-slice: 1;
    border-style: solid;
}

:dir(rtl) {

    .MuiDateCalendar-root .MuiPickersCalendarHeader-root,
    .MuiDateCalendar-root .MuiPickersArrowSwitcher-root {
        flex-direction: row-reverse;
    }
}

span.tooltip-circle {
    border: 1.5px solid var(--foundation-violet);
    border-radius: 50px;
    width: 18px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    font-size: 14px;
    font-weight: 700;
}

.prescription-tooltip.tooltip {
    border: none;
    z-index: 9999;
    opacity: 1;
}

.prescription-tooltip .tooltip-inner {
    color: var(--foundation-violet);
    max-width: 75%;
    background: #e9e7ec;
    font-weight: 600;
}

.add-template-btn span.tooltip-circle {
    font-size: 18px;
}

.summaries-list-section .listdocument {
    margin: 0px;
}

.summaries-list-section .fileList {
    border: none;
}

.summaries-list-section {
    padding-bottom: 38px;
}

.doctor-address {
    display: flex;
    align-items: center;
    gap: 12px;
}
.doctor-address.eng-text {
    flex-direction: row-reverse;
}
.doctor-address .form-label {
    margin: 0px;
    font-weight: 700;
    color: var(--text-primary);
    width: unset;
    min-width: 20%;
    direction: ltr;
}
.doctor-address .btn-primary {
    font-size: 16px;
    font-weight: bold;
    border-radius: 28px;
}

.slideThree {
    min-width: 100px;
    min-width: 40px;
    height: 24px;
    background: rgba(233, 233, 234, 1);
    position: relative;
    border-radius: 100px;
    box-shadow: 0 0 0px rgba(233, 233, 234, 1);
}
.slideThree.disable-check * {
    cursor: not-allowed;
}
.slideThree:after {
    content: attr(data-translation);
    color: var(--foundation-btn-color);
    position: absolute;
    right: 10px;
    z-index: 0;
    font-size: 14px;
    font-weight: bold;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, .15);
    /* background: blue; */
    width: 100%;
    height: 100%;
    line-height: 22px;
    display: none;
}
.slideThree:before {
    content: attr(data-translation);
    color: var(--text-primary);
    position: absolute;
    left: 10px;
    z-index: 0;
    font-size: 14px;
    font-weight: bold;
    /* background: green; */
    width: 100%;
    height: 100%;
    line-height: 22px;
    display: none;
}
.slideThree.false:after {
    display: none;
} 
.slideThree.true:before {
    display: none;
} 
.slideThree label {
    display: block !important;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer !important;
    position: absolute !important;
    top: 2px;
    right: 2px;
    z-index: 1;
    background: #fff;
    /* background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%); */
    border-radius: 50px !important;
    transition: all 0.4s ease;
    box-shadow: 0 0 0px rgba(233, 233, 234, 1) ;
}
.slideThree.EN label {
    left: 2px;
    right: unset;
}
.slideThree input[type=checkbox] {
    visibility: hidden;
}
.slideThree input[type=checkbox]:checked + label {
    right: calc(100% - 22px);
}
.slideThree.EN input[type=checkbox]:checked + label {
    left: calc(100% - 22px);
    right: unset;
}

.slideThree.true {
    /* background: var(--foundation-violet); */
}
.id-label {
    justify-content: space-between;
    display: flex !important;
    align-items: center;
}
.id-label p {
    margin: 0;
}
.label-active {
    color: var(--foundation-btn-color);
}

.disable-select {
    cursor: not-allowed;
    opacity: 0.5 !important;
}


@media (max-width: 767px) {
    .doctor-address .form-label {
        width: calc(100% - 86px);
    }
    .create-prescription-page {
        padding: 40px 0px;
    }
    .create-prescription-title {
        padding-bottom: 24px;
    }
    .create-prescription-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .create-prescription-page .confirmation-agree-section .form-label {
        padding: 0px;
        margin-bottom: 10px;
    }
    .create-prescription-page .confirmation-input-section {
        gap: 12px;
    }
    .create-prescription-page .prescription__option {
        font-size: 22px;
    }
}